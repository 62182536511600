import React from "react";
import Menu from '../components/Menu';
import Header from "./Header";
import Footer from "./Footer";
import styles from '../styles/Template.module.css'; // Подключаем стили

const Template = ({ children }) => {

 
    

  return (
    <div className={styles.template}>
      <Header />

      <div className={styles.menu}>
        <Menu />
      </div>

      <div className={styles.body}>
        <div className={`${styles.left} ${styles.item}`}></div>
        <div className={`${styles.centr} ${styles.item}`}>
          {children} {/* Отображаем компоненты Home и About здесь */}
        </div>
        <div className={`${styles.right} ${styles.item}`}></div>
      </div>

    

      <Footer />
    </div>
  );
};

export default Template;

